import { fetchWrapperPortal } from "./fetch";

export const getAll = async () =>
  await fetchWrapperPortal({
    url: `streams/`,
    method: `GET`,
    addBearer: true
  });

export const getByUser = async userId =>
  await fetchWrapperPortal({
    url: `streams/?user_id=${userId}`,
    method: `GET`,
    addBearer: true
  });

export const getById = async id =>
  await fetchWrapperPortal({
    url: `streams/${id}`,
    method: `GET`,
    addBearer: true
  });

export const getMinimumById = async id =>
  await fetchWrapperPortal({
    url: `streams/${id}/minimum`,
    method: `GET`,
    addBearer: true
  });

export const getDataById = async id =>
  await fetchWrapperPortal({
    url: `streams/${id}/data`,
    method: `GET`,
    addBearer: true
  });

export const getChatByStreamId = async id =>
  await fetchWrapperPortal({
    url: `streams/${id}/chats`,
    method: `GET`,
    addBearer: true
  });

export const getTipsByStreamId = async id =>
  await fetchWrapperPortal({
    url: `streams/${id}/tips/`,
    method: `GET`,
    addBearer: true
  });

export const getByEventId = async id =>
  await fetchWrapperPortal({
    url: `streams/?event_id=${id}`,
    method: `GET`,
    addBearer: true
  });

export const deleteById = async id =>
  await fetchWrapperPortal({
    url: `streams/${id}`,
    method: `DELETE`,
    addBearer: true
  });

export const getPolls = async streamId =>
  await fetchWrapperPortal({
    url: `streams/${streamId}/polls/`,
    method: `GET`,
    addBearer: true
  });

export const getPublishToken = async streamId =>
  await fetchWrapperPortal({
    url: `streams/${streamId}/publish_token/`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({})
  });
//
// export const addBroadcast = async (streamId, otStreamId) =>
//   await fetchWrapperPortal({
//     url: `streams/${streamId}/add_broadcast/`,
//     method: "POST",
//     addBearer: true,
//     body: JSON.stringify({ otstream: otStreamId })
//   });

export const getWaitingToken = async streamId =>
  await fetchWrapperPortal({
    url: `streams/${streamId}/publish_token/`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({ waiting: true })
  });

export const create = async payload =>
  await fetchWrapperPortal({
    url: "streams/",
    method: "POST",
    addBearer: true,
    body: JSON.stringify(payload)
  });

// export const update = async (payload, id) =>
//   await fetchWrapperPortal({
//     url: `streams/${id}`,
//     method: `PUT`,
//     addBearer: true,
//     body: JSON.stringify(payload)
//   });

// Separated stream update endpoints: --------------

export const update = async (payload, id) =>
  await fetchWrapperPortal({
    url: `streams/${id}`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(payload)
  });

export const applySetup = async (payload, id) =>
  await fetchWrapperPortal({
    url: `streams/${id}/setup`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(payload)
  });

export const startChannel = async (payload, id) =>
  await fetchWrapperPortal({
    url: `streams/${id}/start_channel`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(payload)
  });

export const healthcheckRender = async streamId =>
  await fetchWrapperPortal({
    url: `streams/${streamId}/healthcheck_render/`,
    method: "GET",
    addBearer: true
  });

export const goLive = async (payload, id) =>
  await fetchWrapperPortal({
    url: `streams/${id}/go_live`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(payload)
  });

export const endStream = async (payload, id) =>
  await fetchWrapperPortal({
    url: `streams/${id}/end_stream`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(payload)
  });

// -------------------------------------------------

export const getCampaigns = async () =>
  await fetchWrapperPortal({
    url: `campaigns/`,
    method: `GET`,
    addBearer: true
  });

export const updateOverlay = async (user, overlay) => {
  await fetchWrapperPortal({
    url: `streams/${user}`,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify({ custom_overlay: overlay })
  });
};

export const takeBreak = async (id, state) =>
  await fetchWrapperPortal({
    url: `streams/${id}/toggle_break`,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify({ take_break: state })
  });

export const createPoll = async (streamId, poll) =>
  await fetchWrapperPortal({
    url: `streams/${streamId}/polls/`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify(poll)
  });

export const sendBanner = async (_id, streamId) =>
  await fetchWrapperPortal({
    url: `streams/${streamId}/banners/${_id}/send`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify({})
  });

export const addCohost = async (stream, object) =>
  await fetchWrapperPortal({
    url: `streams/${stream}/cohosts/`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify(object)
  });

export const removeCohost = async (stream, user, block = false) =>
  await fetchWrapperPortal({
    url: `streams/${stream}/cohosts/${user}`,
    method: `DELETE`,
    addBearer: true
  });

export const addGuest = async (stream, object) =>
  await fetchWrapperPortal({
    url: `streams/${stream}/guests/`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify(object)
  });

export const removeGuest = async (stream, user, block = false) =>
  await fetchWrapperPortal({
    url: `streams/${stream}/guests/${user}`,
    method: `DELETE`,
    addBearer: true,
    body: JSON.stringify({
      block_user: block
    })
  });

export const startSync = async id => {
  await fetchWrapperPortal({
    url: `streams/${id}/sync`,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify({})
  });
};
