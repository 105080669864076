import { fetchWrapperPortal } from "./fetch";

export const getAll = async (currentPagination = 1, paginationSize = 50, query = null) => {
  const skip = (currentPagination - 1) * paginationSize;
  console.log("getting all overlays!");
  return await fetchWrapperPortal({
    url: `images?type=overlay&skip=${skip}&limit=${paginationSize}${query ? `&search=${query}` : ""}`,
    method: `GET`,
    addBearer: true
  });
};

export const getById = async id =>
  await fetchWrapperPortal({
    url: `images/${id}`,
    method: `GET`,
    addBearer: true
  });

export const search = async orgId =>
  await fetchWrapperPortal({
    url: `images/?search=${orgId}`,
    method: `GET`,
    addBearer: true
  });

export const add = async payload => {
  payload.type = "overlay";
  return await fetchWrapperPortal({
    url: `images/`,
    method: `POST`,
    body: JSON.stringify(payload),
    addBearer: true
  });
};

export const update = async (imageId, payload) => {
  payload.type = "overlay";
  return await fetchWrapperPortal({
    url: `images/` + imageId,
    method: `PUT`,
    body: JSON.stringify(payload),
    addBearer: true
  });
};

export const del = async imageId =>
  await fetchWrapperPortal({
    url: `images/` + imageId,
    method: `DELETE`,
    addBearer: true
  });

export const uploadImage = async (file, type) => {
  console.log("upload", file.type, type);
  const result = await fetchWrapperPortal({
    url: `users/image_upload`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify({
      content_type: file.type,
      image_type: "overlay"
    })
  });

  return await fetch(result.put_url, {
    method: "PUT",
    body: file
  }).then(e => {
    if (e.ok) {
      console.log("image", result.get_url);
      return result.get_url;
    }
    throw e;
  });
};
