import { fetchWrapperPortal } from "./fetch";

export const getAll = async (currentPagination = 1, paginationSize = 50, query = null) => {
  const skip = (currentPagination - 1) * paginationSize;
  return await fetchWrapperPortal({
    url: `videos?skip=${skip}&limit=${paginationSize}${query ? `&search=${query}` : ""}`,
    method: `GET`,
    addBearer: true
  });
};

export const getById = async id =>
  await fetchWrapperPortal({
    url: `videos/${id}`,
    method: `GET`,
    addBearer: true
  });

export const search = async orgId =>
  await fetchWrapperPortal({
    url: `videos/?search=${orgId}`,
    method: `GET`,
    addBearer: true
  });

export const add = async video =>
  await fetchWrapperPortal({
    url: `videos/`,
    method: `POST`,
    body: JSON.stringify(video),
    addBearer: true
  });

export const update = async (videoId, payload) =>
  await fetchWrapperPortal({
    url: `videos/` + videoId,
    method: `PUT`,
    body: JSON.stringify(payload),
    addBearer: true
  });

export const del = async videoId =>
  await fetchWrapperPortal({
    url: `videos/` + videoId,
    method: `DELETE`,
    addBearer: true
  });
