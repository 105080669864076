import React, { useState } from "reactn";
import { Route } from "react-router-dom";
import ProLayout from "@ant-design/pro-layout";
import { Layout } from "antd";

import { useGlobalLoader, GlobalLoader, useGlobalTheme } from "utils/global";
import { authAPI } from "utils/portal-apis"; // TODO

import { publicDefaultSettings, RightContent } from "./common";

function PublicRoute({ component: Component, ...rest }) {
  const [showSignIn, setShowSignIn] = useState(false);

  const { loading } = useGlobalLoader();
  const theme = useGlobalTheme();

  return (
    <Route
      {...rest}
      render={props => {
        if (authAPI.isLoggedIn() && showSignIn) {
          console.log("User is logged in but accessing public page");
          setShowSignIn(false);
        }

        return (
          <GlobalLoader loading={loading}>
            <ProLayout
              {...Object.assign(publicDefaultSettings, { logo: theme.logo })}
              rightContentRender={RightContent}
              isPublicPage={true}
              showSignIn={showSignIn}
              signInSetter={v => setShowSignIn(v)}
            >
              <Layout>
                <Layout.Content style={{ margin: "-28px -20px -24px" }}>
                  <Component {...props} />
                </Layout.Content>
              </Layout>
            </ProLayout>
          </GlobalLoader>
        );
      }}
    />
  );
}

export default PublicRoute;
